import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoaderspinnerService {

  showLoadingvar: boolean;
  constructor() { }

  show(){
    this.showLoadingvar = true;
  }
  hide(){
    this.showLoadingvar = false;
  }
}
