import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { IpResult } from '../shared/models/ipResult.model';
import 'rxjs/add/operator/toPromise';

@Injectable({
  providedIn: 'root'
})
export class IpGuardService implements CanActivate {

  private readonly httpOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json'
    })
  };


  constructor(
    private router: Router,
    private http: HttpClient,
  ) {}

  async canActivate(): Promise<boolean> {
    let ipIsValid = false;
    ipIsValid = await this.checkClientIpAddressValid();
    // console.log("IP VALID: ", ipIsValid);
    if (ipIsValid === true) {
      return true;
    } else {
      this.router.navigate(['welcome']);
      return false;
    }
  }

  async checkClientIpAddressValid(): Promise<boolean> {
    const ipQueryResults = await this.http.get<Promise<any>>('https://jsonip.com').toPromise();
    const url = `${environment.appEngineURL}/tsbremediation/ipcheck?ipAddress=${ipQueryResults.ip}`;
    const ipResult = await this.http.get<Promise<IpResult>>(url, this.httpOptions).toPromise();
    return ipResult.isValid;
  }
}
