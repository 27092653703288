import { ValidatorFn, ValidationErrors, AbstractControl } from "@angular/forms";

export function requiredIfValidator(requiredIfFunction: (value: any) => void): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

        const value = control.value;
        if ((value == null || value == undefined || value == "") && requiredIfFunction(value)) {
            return {
                required: { condition: value }
            };
        }
        return null;
    };
}
