import { Component, OnInit } from '@angular/core';
import { trigger, state, style, query, stagger, animateChild, group, transition, animate, keyframes } from '@angular/animations';
import { LoaderspinnerService } from '../../services/loaderspinner.service';

@Component({
  selector: 'app-loaderspinner',
  templateUrl: './loaderspinner.component.html',
  styleUrls: ['./loaderspinner.component.scss'],
  animations: [
    trigger('fadeIn', [
      transition('void => *', [
        query(':self', style({ opacity: 0 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0.5s ease-out', style({ opacity: 1, }),
          )]), { optional: false }),
      ]),
      transition('* => void', [
        query(':self', style({ opacity: 1 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0.5s ease-out', style({ opacity: 0 }),
          )]), { optional: false }),
      ])
    ])]
})
export class LoaderspinnerComponent implements OnInit {

  constructor(public loadingspinner: LoaderspinnerService) {  }

  ngOnInit() {
  }

}
