import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/from';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private firebaseAuth: AngularFireAuth) { }

  LoggedIn = false;
  Admin = false;

  getToken(): Observable<string> {
    if (!this.firebaseAuth.auth.currentUser) {
      return Observable.from(this.firebaseAuth.auth.signInAnonymously()
        .then(data => {
          return (this.firebaseAuth.auth.currentUser.getIdToken());
        }));
    } else {
      return Observable.fromPromise(this.firebaseAuth.auth.currentUser.getIdToken(true));
    }
  }

  getLoggedIn() {
    return this.LoggedIn;
  }

  getAdmin() {
    return this.Admin;
  }

  setLoggedIn(isLoggedIn: boolean) {
    this.LoggedIn = isLoggedIn;
  }

  setAdmin(isAdmin: boolean) {
    this.Admin = isAdmin;
  }
}
