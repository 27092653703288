import { Injectable } from '@angular/core';
import { AuthService } from '../auth.service';
import { Router, CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router) { }

    canActivate(): boolean {
    if (!this.auth.getLoggedIn()) {
      this.router.navigate(['welcome']);
      return false;
    }
    return true;
  }
}
