import { DownloadcsvComponent } from './downloadcsv/downloadcsv.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomepageComponent } from './welcomepage/welcomepage.component';
import { AdminportalComponent } from './adminportal/adminportal.component';
import { DashboardComponent } from './adminportal/dashboard/dashboard.component';
import { FilesComponent } from './adminportal/files/files.component';
import { AuthGuardService as AuthGuard} from './services/auth-guard.service';
import { RoleGuardService as RoleGuard} from './services/role-guard.service';
import { IpGuardService as IpGuard } from './services/ip-guard.service';


const routes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomepageComponent },
  { path: 'downloadcsv', component: DownloadcsvComponent, canActivate: [AuthGuard] },
  { path: 'adminportal', component: AdminportalComponent, canActivate: [IpGuard]},
  { path: 'admindashboard', component: DashboardComponent, canActivate: [RoleGuard] },
  { path: 'adminfiles', component: FilesComponent, canActivate: [RoleGuard] }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const routingComponents = [
  WelcomepageComponent,
  DownloadcsvComponent,
  AdminportalComponent,
  DashboardComponent,
  FilesComponent
];
