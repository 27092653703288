import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
declare const addressNow: any;

@Injectable({
    providedIn: 'root'
})
export class AddressNowService {

    constructor() { }

    public load() {
        if (addressNow == null) {
            console.log("Error undefined 'addressNow' in AddressNowService");
            return;
        }
        addressNow.load();
    }

    public PersonalListener(){
        addressNow.listen('load', function(control) {
            control.listen("populate", function (address) {
                console.log("AdressNow Service object", address);
                return address.Line1;
            });
        });
    }

    public ThirdPartyListener(){
        addressNow.listen('load', function(control) {
            control.listen("populate", function (address) {
                return address;
            });
        });
    }
}