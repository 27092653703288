import { Complaint } from './../../shared/models/complaint.model';
import { Component, OnInit } from '@angular/core';
import { LoaderspinnerService } from '../../services/loaderspinner.service';
import { trigger, style, query, stagger, transition, animate } from '@angular/animations';
import { StorageService } from '../../services/storage.service';
import { ComplaintInterface } from '../../shared/interfaces/complaint.model.interface';
import { saveAs } from 'file-saver';
import { FormControl, FormGroup, Validators, ValidationErrors, EmailValidator } from '@angular/forms';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query(':self', style({ opacity: 0 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('1s ease-out', style({ opacity: 1, }),
          )]), { optional: false }),
      ]),
      transition('* => void', [
        query(':self', style({ opacity: 1 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0s ease-out', style({ opacity: 0 }),
          )]), { optional: false }),
      ])
    ]),
    trigger('MoveUpShortAnimation', [
      transition('void => *', [
        query(':self', style({ opacity: 0, transform: 'translateY(50%)' }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }),
          )]), { optional: false }),
      ]),
      transition('* => void', [
        query(':self', stagger('0ms', [
          animate('0.2s ease-out', style({ opacity: 0, transform: 'translateY(50%)' }),
          )]), { optional: false }),
      ])

    ])]
})
export class DashboardComponent implements OnInit {
  panelOpenState = false;



  complaintDataList: Array<ComplaintInterface>;
  complaintEvidence: any;
  showNoRecordsMessage = false;

  disableComplaintSubmitted = false;
  disableComplaintRecorded = false;
  disableComplaintUnderReview = false;
  disableComplaintResolved = false;

  statusToSave: string;
  searchRefIdform: FormGroup;

  complaintStatusArray = [
    { name: 'Complaint submitted', checked: false, disabled: false, complantReference: "" },
    { name: 'Complaint recorded', checked: false, disabled: false, complantReference: "" },
    { name: 'Complaint under review', checked: false, disabled: false, complantReference: "" },
    { name: 'Complaint resolved', checked: false, disabled: false, complantReference: "" },
  ];

  constructor(private storage: StorageService, private loadingspinner: LoaderspinnerService, ) { }

  ngOnInit() {
    this.searchRefIdform = new FormGroup({
      'querySearch': new FormControl('', [Validators.pattern('^[0-9]+$')])
    });
    this.complaintDataList = new Array<ComplaintInterface>();
    this.loadingspinner.show();
    this.storage.getAllComplaints().subscribe(data => {
     
      for (let x = 0; x < data.length; x++) {
        const complaintInfo = {} as Complaint;
        complaintInfo.ComplaintReference = data[x].complaintReference;
        complaintInfo.status = data[x].status;
       
        if (data[x].status === 'Complaint submitted') {
          complaintInfo.isComplaintSubmitted = true;
          complaintInfo.isComplaintRecorded = false;
          complaintInfo.isComplaintUnderReview = false;
          complaintInfo.isComplaintResloved = false;
          complaintInfo.disableComplaintSubmitted = false;
          complaintInfo.disableComplaintRecorded = false;
          complaintInfo.disableComplaintUnderReview = false;
          complaintInfo.disableComplaintResolved = false;
        } else if (data[x].status === 'Complaint recorded') {
          complaintInfo.isComplaintSubmitted = false;
          complaintInfo.isComplaintRecorded = true;
          complaintInfo.isComplaintUnderReview = false;
          complaintInfo.isComplaintResloved = false;
          complaintInfo.disableComplaintSubmitted = true;
          complaintInfo.disableComplaintRecorded = false;
          complaintInfo.disableComplaintUnderReview = false;
          complaintInfo.disableComplaintResolved = false;
        } else if (data[x].status === 'Complaint under review') {
          complaintInfo.isComplaintSubmitted = false;
          complaintInfo.isComplaintRecorded = false;
          complaintInfo.isComplaintUnderReview = true;
          complaintInfo.isComplaintResloved = false;
          complaintInfo.disableComplaintSubmitted = true;
          complaintInfo.disableComplaintRecorded = true;
          complaintInfo.disableComplaintUnderReview = false;
          complaintInfo.disableComplaintResolved = false;
        } else if (data[x].status === 'Complaint resolved') {
          complaintInfo.isComplaintSubmitted = false;
          complaintInfo.isComplaintRecorded = false;
          complaintInfo.isComplaintUnderReview = false;
          complaintInfo.isComplaintResloved = true;
          complaintInfo.disableComplaintSubmitted = true;
          complaintInfo.disableComplaintRecorded = true;
          complaintInfo.disableComplaintUnderReview = true;
          complaintInfo.disableComplaintResolved = false;
        }

        this.complaintDataList.push(complaintInfo);
      }

      this.loadingspinner.hide();      
    });


  }

  getComplaintEvidence(complaintReference) {
    this.complaintEvidence = null;
    this.loadingspinner.show();
    this.showNoRecordsMessage = false;
    this.storage.getComplaintEvidence(complaintReference).subscribe(data => {
      if (data.length === 0) {
        this.showNoRecordsMessage = true;
      }

      this.complaintDataList.forEach((item, index) => {
        if (item.ComplaintReference == complaintReference) {
          if (item.status == "Complaint submitted") {
            item.disableComplaintSubmitted = false;
            item.disableComplaintRecorded = false;
            item.disableComplaintUnderReview = false;
            item.disableComplaintResolved = false;
          }
          else if (item.status == "Complaint recorded") {
            item.disableComplaintSubmitted = true;
            item.disableComplaintRecorded = false;
            item.disableComplaintUnderReview = false;
            item.disableComplaintResolved = false;
          }
          else if (item.status == "Complaint under review") {
            item.disableComplaintSubmitted = true;
            item.disableComplaintRecorded = true;
            item.disableComplaintUnderReview = false;
            item.disableComplaintResolved = false;
          }
          else if (item.status == "Complaint resolved") {
            item.disableComplaintSubmitted = true;
            item.disableComplaintRecorded = true;
            item.disableComplaintUnderReview = true;
            item.disableComplaintResolved = false;
          }
        }
      });

      this.complaintEvidence = data;
      this.panelOpenState = true;
      this.loadingspinner.hide();
    });
  }

  downloadEvidence(fileName, complaintReference) {

    const path = complaintReference + '/' + fileName;    

    this.loadingspinner.show();
    this.storage.donwloadEvidence(path).subscribe(data => {      
      const buff = require('buffer/').Buffer.from(data[0]);
      const fileBlob = new Blob([buff], { type: 'any' });
      saveAs(new Blob([fileBlob]), fileName);
      this.loadingspinner.hide();
    });
  }

  saveStatus(fileName) {
    this.loadingspinner.show();
    this.complaintDataList.forEach(complaint => {
      if (complaint.ComplaintReference === fileName) {
       
        this.storage.updateClaimStatus(fileName, this.statusToSave).subscribe(res => {
          
          if (res.isSuccess) {
            complaint.status = this.statusToSave;
            if (this.statusToSave == "Complaint submitted") {
              console.log("Complaint submitted");
              complaint.disableComplaintSubmitted = false;
              complaint.disableComplaintRecorded = false;
              complaint.disableComplaintUnderReview = false;
              complaint.disableComplaintResolved = false;
            }
            else if (this.statusToSave == "Complaint recorded") {
              console.log("Complaint recorded");
              complaint.disableComplaintSubmitted = true;
              complaint.disableComplaintRecorded = false;
              complaint.disableComplaintUnderReview = false;
              complaint.disableComplaintResolved = false;
            }
            else if (this.statusToSave == "Complaint under review") {
              console.log("Complaint under review");
              complaint.disableComplaintSubmitted = true;
              complaint.disableComplaintRecorded = true;
              complaint.disableComplaintUnderReview = false;
              complaint.disableComplaintResolved = false;
            }
            else if (this.statusToSave == "Complaint resolved") {
              console.log("Complaint resolved");
              complaint.disableComplaintSubmitted = true;
              complaint.disableComplaintRecorded = true;
              complaint.disableComplaintUnderReview = true;
              complaint.disableComplaintResolved = false;
            }

            this.loadingspinner.hide();
          }
          else{
            this.loadingspinner.hide();
          }
        });
      }      
    });
  }

  onStatusChange(complaintReference, status) {    
    this.statusToSave = status;
  }



}
