import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-downloadcsv',
  templateUrl: './downloadcsv.component.html',
  styleUrls: ['./downloadcsv.component.scss']
})
export class DownloadcsvComponent implements OnInit {

  returnUrl: string;
  constructor(private route: ActivatedRoute,private router: Router) { }

  ngOnInit() {
    
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

    window.open(this.returnUrl);
    
  }

}
