import { StorageService } from './services/storage.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { AppRoutingModule, routingComponents } from './app-routing.module';
import { AngularFireModule } from 'angularfire2';
import { AngularFireAuth } from 'angularfire2/auth';
import { TopnavbarComponent } from './topnavbar/topnavbar.component';
import { WelcomepageComponent } from './welcomepage/welcomepage.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha/forms';
import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './token-interceptor';

import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { OwlMomentDateTimeModule } from 'ng-pick-datetime-moment';

import { DatemaskDirective } from './shared/directives/datemask.directive';
import { DownloadcsvComponent } from './downloadcsv/downloadcsv.component';
import { AutodownloadDirective } from './shared/directives/autodownload.directive';

import { LoaderspinnerComponent } from './shared/loaderspinner/loaderspinner.component';
import { DownloadpdfComponent } from './downloadpdf/downloadpdf.component';
import { AdminportalComponent } from './adminportal/adminportal.component';
import { DashboardComponent } from './adminportal/dashboard/dashboard.component';

import {MatExpansionModule} from '@angular/material/expansion';
import {MatDialogModule} from '@angular/material';

import { FullTextSearchPipe } from './shared/pipes/searchIdPipe';
import { FilesComponent } from './adminportal/files/files.component';
import { MenuComponent } from './adminportal/menu/menu.component';
import { PortallogoutComponent } from './shared/dialogs/portallogout/portallogout.component';


export const MY_MOMENT_FORMATS = {
  parseInput: 'l',
  fullPickerInput: 'DD/MM/YYYY',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};


@NgModule({
  declarations: [
    AppComponent,
    TopnavbarComponent,
    routingComponents,
    WelcomepageComponent,
    BreadcrumbsComponent,
    DatemaskDirective,
    DownloadcsvComponent,
    AutodownloadDirective,
    LoaderspinnerComponent,
    DownloadpdfComponent,
    AdminportalComponent,
    DashboardComponent,
    FullTextSearchPipe,
    FilesComponent,
    MenuComponent,
    PortallogoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AppRoutingModule,
    RecaptchaModule.forRoot(),
    RecaptchaFormsModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    OwlMomentDateTimeModule,
    MatExpansionModule,
    MatDialogModule
  ],
  entryComponents: [PortallogoutComponent],
  providers: [StorageService, AngularFireAuth, {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptor,
    multi: true,
  }, {provide: OWL_DATE_TIME_LOCALE, useValue: 'en-GB'},  {provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS},
],
  bootstrap: [AppComponent]
})
export class AppModule { }
