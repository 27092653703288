import { ComplaintInterface } from './../shared/interfaces/complaint.model.interface';
import { Complaint } from './../shared/models/complaint.model';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private storageUrl = `${environment.appEngineURL}/tsbremediation/`;
    // private storageUrl = `http://localhost:3000/tsbremediation/`;

    private readonly httpOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
    };

    private readonly httpOptionsFormData = {
        headers: new HttpHeaders({
            'Accept': 'application/json'
        })
    };

    constructor(
        private http: HttpClient
    ) { }

    public validateReferenceId(ComplaintReference: string): Observable<any> {
        console.log('validateReferenceId');
        const url = `${this.storageUrl}validateReferenceId`;

        return this.http.post<any>(url, { ComplaintReference: ComplaintReference }, this.httpOptions);
    }

    public getPersonalTitles(): Observable<any> {
        console.log('getPersonalTitles');
        const url = `${this.storageUrl}personaltitles`;

        return this.http.get<any>(url, this.httpOptions);
    }

    public saveComplaintToDataStore(complaintReference: string, complaintData: Complaint): Observable<any> {
        console.log('saveApplicationToDataStore');
        const url = `${this.storageUrl}saveComplaint`;

        return this.http.post<any>(url, { complaintReference: complaintReference, complaintBlob: complaintData }, this.httpOptions);
    }

    public saveComplaintMI(complaintReference: string, complaintData: Complaint, lastStep: string): Observable<string> {
        console.log('saveComplaintMI' + JSON.stringify(complaintData));
    
        const url = `${this.storageUrl}saveComplaintMI`;

        return this.http.post<any>(
            url,
            {
                complaintReference: complaintReference,
                complaintBlob: complaintData,
                lastStep: lastStep
             },
            this.httpOptions
        );
    }

    public uploadDocument(complaintReference: string, file: any): Observable<any> {
        console.log('uploadDocument');
        const url = `${this.storageUrl}documantUpload`;

        const formData: FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('complaintReference', complaintReference);

        return this.http.post<any>(url, formData, this.httpOptionsFormData);
    }

    public deleteDocument(documentName: string): Observable<any>{
        console.log('deleteDocument');
        const url = `${this.storageUrl}deleteDocument`;
        // console.log(url);
        // console.log(username + ' ' + password);
        return this.http.post<any>(url, { fileName: documentName }, this.httpOptions);
    }

    public validatePartnerCredentials(username: string, password: string): Observable<any> {
        console.log('validatePartnerCredentials');
        const url = `${this.storageUrl}partnerLoginValidation`;
        // console.log(url);
        // console.log(username + ' ' + password);
        return this.http.post<any>(url, { username: username, password: password }, this.httpOptions);
    }

    public getAllComplaints(): Observable<any> {
        console.log('getAllComplaints');
        const url = `${this.storageUrl}getAllComplaints`;

        return this.http.get<any>(url, this.httpOptions);
    }

    public getComplaintEvidence(complaintReference: string): Observable<any> {
        console.log('getComplaintEvidence');
        const url = `${this.storageUrl}getComplaintEvidence?complaintReference=${complaintReference}`;

        return this.http.get<any>(url, this.httpOptions);
    }

    public updateClaimStatus(complaintReference: string, status: string) {
        console.log('validatePartnerCredentials');
        const url = `${this.storageUrl}updateComplaintStatus`;
        const formData: FormData = new FormData();
        formData.append('complaintReference', complaintReference);
        formData.append('status', status);
        // console.log(url);

        return this.http.post<any>(url, formData, this.httpOptionsFormData);
    }

    public getAllCSVFiles(): Observable<any> {
        console.log('getAllCSVFiles');
        const url = `${this.storageUrl}getAllCsvs`;

        return this.http.get<any>(url, this.httpOptions);
    }

    public updateCSVDownloadStatus(fileName: string): Observable<any> {
        console.log('updateCSVDownloadStatus');
        const url = `${this.storageUrl}updateCsvDownloadStatus`;
        const formData: FormData = new FormData();
        formData.append('fileName', fileName);

        return this.http.post<any>(url, formData, this.httpOptionsFormData);
    }

    public donwloadCSV(fileName: string) {
        console.log('validatePartnerCredentials');
        const url = `${this.storageUrl}getdailycsv?fileName=${fileName}`;

        const httpOptionsForCsv = {
            headers: new HttpHeaders({
                'Content-Type': 'text/csv',
                'Accept': 'text/csv'
            })

            // responseType: 'text/csv' as 'json'
        };
        return this.http.get(url, httpOptionsForCsv);
    }

    public donwloadEvidence(fileName: string) {
        console.log('validatePartnerCredentials');
        const url = `${this.storageUrl}getdailycsv?fileName=${fileName}`;

        const httpOptionsForCsv = {
            headers: new HttpHeaders({
                // 'Content-Type': 'text/csv',
                // 'Accept': 'text/csv'
            })

            // responseType: 'text/csv' as 'json'
        };
        return this.http.get(url, httpOptionsForCsv);
    }

    public generateCsv(): Observable<any>{
        console.log('generateCsv');
        const url = `${this.storageUrl}generateCSV`;
        return this.http.post<any>(url, this.httpOptions);
    }

    
}
