// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCgY8cKOK97kM_MJNMejnkjSwXNVnCznCI",
    authDomain: "tsb-remediation-s.firebaseapp.com",
    databaseURL: "https://tsb-remediation-s.firebaseio.com",
    projectId: "tsb-remediation-s",
    storageBucket: "tsb-remediation-s.appspot.com",
    messagingSenderId: "1081018250617"
  },
  appEngineURL: 'https://vaultigo-dev-194710.appspot.com',
   //appEngineURL: 'http://localhost:3000',
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
