import { Directive, ElementRef, HostListener, Output, EventEmitter, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAutodownload]'
})
export class AutodownloadDirective {

  @Output('download-start') downloadStartEvent: EventEmitter<any> = new EventEmitter();
  @Output('downloaded') downloadedEvent: EventEmitter<any> = new EventEmitter();


  constructor(
    private elementRef: ElementRef,
  ) { }

  @HostListener('download-start', ['$event']) onEventDownloadStart($event) {
    this.elementRef.nativeElement.attr('disabled', 'disabled');
  }

  @HostListener('downloaded', ['$event', '$data']) onEventDownloaded($event, $data) {
    //this.renderer
    this.elementRef.nativeElement.attr({
      href: 'data:application/pdf;base64,' + $data,
      download: 'test.txt' //this.elementRef.nativeElement.attr.filename
    })
      .removeAttr('disabled')
      .text('Save')
      .removeClass('btn-primary')
      .addClass('btn-success');
  }

  @HostListener('download', ['$event']) onEventDownload($event) {

  }

  @HostListener('click', ['$event', '$data']) onEventClick($event, $data) {

    this.downloadStartEvent.emit();

    debugger;
    // TODO : Do da Get

    this.downloadedEvent.emit('my text test file');

    // $scope.$emit('download-start');
    // $http.get($attrs.url).then(function (response) {
    //   $scope.$emit('downloaded', response.data);
    // });

  }



}
