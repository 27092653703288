import { ThirdPartyInterface } from "../interfaces/thirdParty.model.interface";

export class ThirdParty implements ThirdPartyInterface{
    RelationshipType: string;    
    Name: string;
    Surname: string;
    ContactNumber: string;
    DateOfBirth: Date;
    EmailAddress: string;
    Address: string;
    PostalCode: string;
}