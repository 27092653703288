import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormArray, Validators, ValidationErrors, EmailValidator } from '@angular/forms';
import { LoaderspinnerService } from '../services/loaderspinner.service';
import { DatemaskService } from '../services/datemask.service';
import { Moment } from '../../../node_modules/moment';
import { trigger, style, query, stagger, transition, animate } from '@angular/animations';
import { StorageService } from './../services/storage.service';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-adminportal',
  templateUrl: './adminportal.component.html',
  styleUrls: ['./adminportal.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query(':self', style({ opacity: 0 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('1s ease-out', style({ opacity: 1, }),
          )]), { optional: false }),
      ]),
      transition('* => void', [
        query(':self', style({ opacity: 1 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0s ease-out', style({ opacity: 0 }),
          )]), { optional: false }),
      ])
    ]),
    trigger('MoveUpShortAnimation', [
      transition('void => *', [
        query(':self', style({ opacity: 0, transform: 'translateY(50%)' }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }),
          )]), { optional: false }),
      ]),
      transition('* => void', [
        query(':self', stagger('0ms', [
          animate('0.2s ease-out', style({ opacity: 0, transform: 'translateY(50%)' }),
          )]), { optional: false }),
      ])

    ])]
})
export class AdminportalComponent implements OnInit {

  AdminLoginForm: FormGroup;
  submitted: boolean = false;
  checkPasswordInValid: boolean = false;

  constructor(
    private storage: StorageService,
    private loadingspinner: LoaderspinnerService,
    private router: Router,
    private auth: AuthService) { }

  ngOnInit() {

    this.AdminLoginForm = new FormGroup({
      // 'title': new FormControl(this.Complaint.Title),
      //  'username': new FormControl('reza@test.com', [Validators.required]),  //TODO: remove hard coding
      //  'password': new FormControl('P@ssw0rd!@#', [Validators.required]) //TODO: remove hard coding

       'username': new FormControl('', [Validators.required]),
       'password': new FormControl('', [Validators.required])
    });
  }

  login(){
    this.submitted = true;
    if(this.AdminLoginForm.valid){
      this.loadingspinner.show();
      this.storage.validatePartnerCredentials(this.AdminLoginForm.controls["username"].value, this.AdminLoginForm.controls["password"].value).subscribe(data =>{
        if(data.isSuccess){
          this.loadingspinner.hide();
          this.auth.setAdmin(true);
          this.router.navigate(['/admindashboard']);
        }
        else{
          this.loadingspinner.hide();
          this.checkPasswordInValid = true;
        }
      });
    }
  }

}
