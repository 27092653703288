import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-downloadpdf',
  templateUrl: './downloadpdf.component.html',
  styleUrls: ['./downloadpdf.component.scss']
})
export class DownloadpdfComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
