
import { element } from 'protractor';
import { PreferedContactTimeInterface } from './../shared/interfaces/PreferedContactTime.model.interface';
import { ComplaintReasonInterface } from './../shared/interfaces/complainReason.model.interface';
import { Observable } from 'rxjs/Observable';
import { Complaint } from './../shared/models/complaint.model';
import { ComplaintInterface } from './../shared/interfaces/complaint.model.interface';
import { StorageService } from './../services/storage.service';
import { Component, OnInit, OnChanges} from '@angular/core';
import { trigger, state, style, query, stagger, animateChild, group, transition, animate, keyframes } from '@angular/animations';
import { FormControl, FormGroup, FormArray, Validators, ValidationErrors, EmailValidator } from '@angular/forms';
import { DatemaskDirective } from './../shared/directives/datemask.directive';
import { LoaderspinnerService } from '../services/loaderspinner.service';
import { FinancialLossesInterface } from './../shared/interfaces/financialLosses.model.interface';
import { DocumentInterface } from '../shared/interfaces/document.model.interface';
import { DatemaskService } from '../services/datemask.service';
import { Moment } from '../../../node_modules/moment';

import { requiredIfValidator } from '../shared/customValidators/required-if-Validators';
import { AuthService } from '../auth.service';
import { AddressNowService } from '../services/addressNow.service';



@Component({
  selector: 'app-welcomepage',
  templateUrl: './welcomepage.component.html',
  styleUrls: ['./welcomepage.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query(':self', style({ opacity: 0 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('1s ease-out', style({ opacity: 1, }),
          )]), { optional: false }),
      ]),
      transition('* => void', [
        query(':self', style({ opacity: 1 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0s ease-out', style({ opacity: 0 }),
          )]), { optional: false }),
      ])
    ]),
    trigger('MoveUpShortAnimation', [
      transition('void => *', [
        query(':self', style({ opacity: 0, transform: 'translateY(50%)' }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }),
          )]), { optional: false }),
      ]),
      transition('* => void', [
        query(':self', stagger('0ms', [
          animate('0.2s ease-out', style({ opacity: 0, transform: 'translateY(50%)' }),
          )]), { optional: false }),
      ])

    ])],
})
export class WelcomepageComponent implements OnInit {

  Complaint: ComplaintInterface = new Complaint();
  continue = false;

  Step1AnimState = true;
  Step2AnimState = false;
  Step3AnimState = false;
  Step4AnimState = false;
  Step5AnimState = false;
  Step5SummaryAnimState = false;
  Step6AnimState = false;

  Step2MILogged = false;
  Step3MILogged = false;
  Step4MILogged = false;

  MYDOB = "";

  mainOtherReasonChecked: boolean = false;
  otherOtherReasonChecked: boolean = false;

  financialLossdetailCheckVar: boolean = false;

  file;
  documentUpload: string = null;
  documentUploaded: boolean;
  documentUploadError: boolean;

  ThirdPartyShow: boolean = false;

  otherReasonViewArray = [
    { name: 'Problems logging into your account', checked: false },
    { name: 'Fees or charges from TSB', checked: false },
    { name: 'Long wait times in branch or over the phone', checked: false },
    { name: 'Fees or charges from another bank, lender or provider', checked: false },
    { name: 'Problems making or receiving payments', checked: false },
    { name: 'Other financial losses', checked: false },
    { name: 'Fraud/data privacy issues', checked: false },
    { name: 'Other', checked: false },
  ];

  preferedContactTimeArray = [
    { name: 'Morning', checked: false },
    { name: 'Afternoon', checked: false },
    { name: 'Evening', checked: false },
  ];

  contactTypes = [
    { name: 'Personal Banking' },
    { name: 'Business Banking' },
    { name: 'Submitting this complaint on behalf of someone else' }
  ];
  productTypes = [
    { name: 'Business current account' },
    { name: 'Personal current account' }
  ];

  titles: any;
  ComplaintTrackingId: string;

  termsAndconditionsChecked = false;
  showMainReasonError = true;
  showPreferedTimeError = false;
  showWelcomeValidationError = false;
  showDateValidationError = false;
  referenceValidationError: string;

  IsCurrentlyOccuring = false;

  welcomeForm: FormGroup;
  aboutForm: FormGroup;
  ReasonForm: FormGroup;
  descriptionForm: FormGroup;
  evidenceForm: FormGroup;

  formEvidenceJson;
  hideFinancialDeleteButton: boolean = true;

  submitted = false;
  dateMaskControl = this.dateMask;

  




  constructor(
    private storage: StorageService,
    private loadingspinner: LoaderspinnerService,
    private dateMask: DatemaskService,
    private auth: AuthService,
    private addressNowService: AddressNowService
  ) { }

 
  ngOnInit() {
   

    this.welcomeForm = new FormGroup({
      'IdNumber': new FormControl('', [Validators.required, Validators.pattern('^[0-9\-]+$')]),
      'recaptchaReactive': new FormControl('', Validators.required)
    });
    this.aboutForm = new FormGroup({
      // 'title': new FormControl(this.Complaint.Title),
      'firstname': new FormControl(this.Complaint.Name, [Validators.required, Validators.pattern('^[A-z\- \']+$')]),
      'surname': new FormControl(this.Complaint.Surname, [Validators.required, Validators.pattern('^[A-z\- \']+$')]),
      'address': new FormControl('', Validators.required),
      'autoAddressLookupHidden': new FormControl(''),
      'postcode': new FormControl('', [Validators.required, Validators.pattern('^[0-9A-z\\s-]+$')]),
      'contactnumber': new FormControl('', [Validators.required, Validators.pattern('^[0-9\+]+$')]),
      'dateofbirth': new FormControl('', Validators.required),
      'email': new FormControl('', [Validators.required, Validators.pattern('^([a-zA-Z0-9_\\.\\-])+\\@(([a-zA-Z0-9\-])+\\.)+([a-zA-Z0-9]{2,4})+$')]),
      'contactType': new FormControl('', Validators.required),
      'ThirdPartyRelationship': new FormControl('', [
        requiredIfValidator(() => {
          return this.ThirdPartyShow;
        }), Validators.pattern('^[A-z\- \']+$')]),
      'ThirdPartyfirstname': new FormControl('', [
        requiredIfValidator(() => {
          return this.ThirdPartyShow;
        }), Validators.pattern('^[A-z\- \']+$')]),
      'ThirdPartysurname': new FormControl('', [
        requiredIfValidator(() => {
          return this.ThirdPartyShow;
        }), Validators.pattern('^[A-z\- \']+$')]),
      'ThirdPartyaddress': new FormControl('', [
        requiredIfValidator(() => {
          return this.ThirdPartyShow;
        })]),
      'ThirdPartypostcode': new FormControl('', [
        requiredIfValidator(() => {
          return this.ThirdPartyShow;
        }), Validators.pattern('^[0-9A-z\\s-]+$')]),
      'ThirdPartycontactnumber': new FormControl('', [
        requiredIfValidator(() => {
          return this.ThirdPartyShow;
        }), Validators.pattern('^[0-9\+]+$')]),
      'ThirdPartydateofbirth': new FormControl('', [
        requiredIfValidator(() => {
          return this.ThirdPartyShow;
        })]),
      'ThirdPartyemail': new FormControl('', [
        requiredIfValidator(() => {
          return this.ThirdPartyShow;
        }), Validators.pattern('^([a-zA-Z0-9_\\.\\-])+\\@(([a-zA-Z0-9\-])+\\.)+([a-zA-Z0-9]{2,4})+$')]),
      //'productType': new FormControl('', Validators.required)
    });
    this.descriptionForm = new FormGroup({
      'datefrom': new FormControl('', [Validators.required]),
      'dateto': new FormControl('', [Validators.required]),
      'aboutclaim': new FormControl(''),
      'howeffectclaim': new FormControl('')
    });
    this.ReasonForm = new FormGroup({
      'mainOtherReasonInput': new FormControl('', [
        requiredIfValidator(() => {
          return this.mainOtherReasonChecked;
        })
      ]),
      'otherOtherReasonInput': new FormControl('', [
        requiredIfValidator(() => {
          return this.otherOtherReasonChecked;
        })
        //Validators.pattern('^[A-z\- \'\.\,]+$)'
      ])
    });
    this.evidenceForm = new FormGroup({
      'financialLosses': new FormArray([])
    });

    this.onChanges();
    // add one financial loss row at start
    this.addFinancialLosses();
    //this.addressNowService.load();   
    
  }

  /*** Financial losses, add and remove */
  buildItemFinancialLosses(val: string) {
    return new FormGroup({
      'description': new FormControl('', [Validators.required]),
      'amount': new FormControl('', [Validators.required, Validators.pattern("^[0-9\.\,]*$")]),
      'provider': new FormControl('', [Validators.required]),
      'refnumber': new FormControl('', [Validators.required])
    });

  }
  addFinancialLosses() {
    (<FormArray>this.evidenceForm.get('financialLosses')).push(this.buildItemFinancialLosses(''));
  }
  removeFinancialLosses(child) {
    (<FormArray>this.evidenceForm.get('financialLosses')).removeAt(child);
  }

  onChanges(): void {
    this.evidenceForm.valueChanges.subscribe(val => {

      this.formEvidenceJson = this.evidenceForm.value;
      if (this.formEvidenceJson.financialLosses.length == 1) {
        this.hideFinancialDeleteButton = true;
      } else {
        this.hideFinancialDeleteButton = false;
      }
    })

    this.descriptionForm.controls["dateto"].valueChanges.subscribe(() => {
      this.showDateValidationError = false;
    })

    this.aboutForm.controls["contactType"].valueChanges.subscribe(val => {
      console.log(val);
      if (val == "Submitting this complaint on behalf of someone else") {
        this.ThirdPartyShow = true;
      } else {
        this.ThirdPartyShow = false;
      }

      this.aboutForm.controls['ThirdPartyRelationship'].updateValueAndValidity();
      this.aboutForm.controls['ThirdPartyfirstname'].updateValueAndValidity();
      this.aboutForm.controls['ThirdPartysurname'].updateValueAndValidity();
      this.aboutForm.controls['ThirdPartyaddress'].updateValueAndValidity();
      this.aboutForm.controls['ThirdPartypostcode'].updateValueAndValidity();
      this.aboutForm.controls['ThirdPartycontactnumber'].updateValueAndValidity();
      this.aboutForm.controls['ThirdPartydateofbirth'].updateValueAndValidity();
      this.aboutForm.controls['ThirdPartyemail'].updateValueAndValidity();
    })

    this.aboutForm.controls["autoAddressLookupHidden"].valueChanges.subscribe(val =>{
      console.log("address changed");
    })
   

  }
  currentlyOccuring(ischecked) {
    this.IsCurrentlyOccuring = ischecked;
  }

  checkTermsAndContitions() {
    if (this.termsAndconditionsChecked) {
      this.termsAndconditionsChecked = false;
    } else {
      this.termsAndconditionsChecked = true;
    }
  }

  MaskedDate(event: any) {
    var v = event.target.value;
    if (v.match(/^\d{2}$/) !== null) {
      event.target.value = v + '/';
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      event.target.value = v + '/';
    }
  }

  resolved(captchaResponse: string) {
    console.log(`Resolved captcha with response ${captchaResponse}:`);
  }

  checkWelcomeDetails(): boolean {
    if (this.welcomeForm.controls['IdNumber'].valid &&
      this.welcomeForm.controls['recaptchaReactive'].valid) {
      return true;
    } else {
      return false;
    }
  }

  continueClick() {
    this.submitted = true;
    if (this.Step1AnimState) {
      if (this.checkWelcomeDetails()) {
        this.loadingspinner.show();
        this.storage.validateReferenceId(this.welcomeForm.controls["IdNumber"].value).subscribe(data => {
          if (data.isSuccess) {
            this.loadingspinner.hide();
            this.showWelcomeValidationError = false;
            this.Step1AnimState = false;
            this.Step2AnimState = true;           
            this.submitted = false;
            this.Complaint.ComplaintReference = this.welcomeForm.controls["IdNumber"].value;           
            this.storage.saveComplaintMI(this.Complaint.ComplaintReference, this.Complaint, "step 1").subscribe(data => { });
            this.auth.setLoggedIn(true);
          }
          else {
            this.loadingspinner.hide();
            this.showWelcomeValidationError = true;
            this.referenceValidationError = data.message;
            return;
          }
        });
      }
    } 
    else if (this.Step2AnimState) {
      this.validateAboutPage();
    } 
    else if (this.Step3AnimState) {
      this.validateComplaintReasonPage();
    } 
    else if (this.Step4AnimState) {
      this.validateComplaintDescriptionPage();
    } 
    
    else if (this.Step5AnimState) {      
      this.submitted = true;
     
        if (this.financialLossdetailCheckVar) {
          if (this.evidenceForm.valid) {
            this.sendFinancialLosses();
          }         
        } 
        else {
          // moved save Complaint to summary screen
          this.submitted = false;
          this.Step5AnimState = false;
          this.Step5SummaryAnimState = true;
         
        }
    
    }
    else if(this.Step5SummaryAnimState){
      if (this.termsAndconditionsChecked) {
        this.saveComplaint();
      }
    }
  }

  loadComplaint(complaintData) {
    this.Complaint.Name = complaintData.complaint_blob
    let obj: Complaint = JSON.parse(complaintData.complaint_blob);

    console.log("complaint blob ", obj);
    console.log("last step ", complaintData.last_step);
    this.Complaint = obj;
  }

  saveComplaint() {
    this.loadingspinner.show();
    this.storage.saveComplaintToDataStore(this.Complaint.ComplaintReference, this.Complaint).subscribe(data => {
     console.log("Save complaint" + this.Complaint);
      if (data.isSuccess) {
        this.loadingspinner.hide();
        this.ComplaintTrackingId = data.TrackingNumberResult;
        this.Step5SummaryAnimState = false;
        this.Step6AnimState = true;            
      }
      else {
        this.Step5SummaryAnimState = false;
        this.Step6AnimState = true;
        this.loadingspinner.hide();
      }
    });
  }


  sendFinancialLosses() {
    this.Complaint.FinancialLosses = []
    this.formEvidenceJson = this.evidenceForm.value;
    for (let x = 0; x < this.formEvidenceJson.financialLosses.length; x++) {
      let financialLossData = {} as FinancialLossesInterface;
      financialLossData.Description = this.formEvidenceJson.financialLosses[x].description;
      financialLossData.Amount = this.formEvidenceJson.financialLosses[x].amount;
      financialLossData.ProviderName = this.formEvidenceJson.financialLosses[x].provider;
      financialLossData.CustomerReferenceNumber = this.formEvidenceJson.financialLosses[x].refnumber;
      this.Complaint.FinancialLosses.push(financialLossData);
    }
    this.submitted = false;
    this.Step5AnimState = false;
    this.Step5SummaryAnimState = true;
    //this.saveComplaint();
  }

  backClick() {
    if (this.Step2AnimState) {
      this.Step1AnimState = true;
      this.Step2AnimState = false;     
    } 
    else if (this.Step3AnimState) {
      this.Step2AnimState = true;
      this.Step3AnimState = false;     
    } 
    else if (this.Step4AnimState) {
      this.Step3AnimState = true;
      this.Step4AnimState = false; 
      // Remove the Other other from the array as it is repeating on summary screen
      for(var x=0; x<= this.Complaint.OtherComplaintReasons.length - 1; x++){
        if(this.Complaint.OtherComplaintReasons[x].Reason == this.ReasonForm.controls['otherOtherReasonInput'].value){
          console.log("this is already added in the array remove it now");
          this.Complaint.OtherComplaintReasons.splice(x,1);
          console.log(this.Complaint.OtherComplaintReasons);
          //this.Complaint.OtherComplaintReasons.splice(element.Reason);
        }
      }
      
    } 
    else if (this.Step5AnimState) {
      this.Step4AnimState = true;
      this.Step5AnimState = false;     
    } 
    else if (this.Step5SummaryAnimState) {
      this.Step5AnimState = true;
      this.Step5SummaryAnimState = false;     
    } 
    else if (this.Step6AnimState) {
      this.Step5AnimState = true;
      this.Step6AnimState = false;      
    }
  }

  financialLossdetailCheck(element) {
    if (this.financialLossdetailCheckVar) {
      this.financialLossdetailCheckVar = false;
    } else {
      this.financialLossdetailCheckVar = true;
    }
  }

  onMainReasonSelected(mainReason) {
    this.showMainReasonError = false;
    if (mainReason == 'other') {
      this.mainOtherReasonChecked = true;
    } else {
      this.mainOtherReasonChecked = false;
    }
    this.Complaint.ComplaintMainReason.Reason = mainReason;    
    this.ReasonForm.controls['mainOtherReasonInput'].updateValueAndValidity();   
  }

  onOtherReasonSelected(otherReason) {
    console.log("Selected other reason" + otherReason);


    if (otherReason == 'Other') {
        if (this.otherOtherReasonChecked) {
          this.otherOtherReasonChecked = false;
          this.otherReasonViewArray.forEach(element => {
            if (element.name == otherReason) {
              element.checked = false;
            }
          });
          this.Complaint.OtherComplaintReasons.forEach((item, index) => {
            if (item.Reason == this.ReasonForm.controls['otherOtherReasonInput'].value) {
              this.Complaint.OtherComplaintReasons.splice(index, 1);
            }
          });
         
          this.ReasonForm.controls['otherOtherReasonInput'].updateValueAndValidity();
         
          return;
        } else {
          this.ReasonForm.controls['otherOtherReasonInput'].setValue('');
          this.otherOtherReasonChecked = true;
          this.otherReasonViewArray.forEach(element => {
            if (element.name == otherReason) {
              element.checked = true;
            }
          });
          this.ReasonForm.controls['otherOtherReasonInput'].updateValueAndValidity();
         
          return;
        }

    }

    var isValidAdd = true;
    var indexCounter = 0;
    this.Complaint.OtherComplaintReasons.forEach((item, index) => {
      if (item.Reason == otherReason) {
        this.Complaint.OtherComplaintReasons.splice(index, 1);
        isValidAdd = false;
      }
    });

    this.otherReasonViewArray.forEach(element => {
      if (element.name == otherReason) {
        element.checked = isValidAdd;
      }
    });

    if (isValidAdd) {
      if (otherReason != "Other") {
        let otherReasonData = {} as ComplaintReasonInterface;
        otherReasonData.Reason = otherReason;
        this.Complaint.OtherComplaintReasons.push(otherReasonData);
      }

      this.otherReasonViewArray.forEach(element => {
        if (element.name == otherReason) {
          element.checked = true;
        }
      });
    }

    console.log("other reasons ", this.Complaint.OtherComplaintReasons);

  }



  validateAboutPage() {
   
    console.log(this.aboutForm.controls['ThirdPartyRelationship'])
    if (this.aboutForm.invalid) {
      if (this.Complaint.PreferedContactTimes.length > 0) {
        this.showPreferedTimeError = false;
      }
      else {
        this.showPreferedTimeError = true;
        isValid = false;
      }
      return;
    }
   
    this.Complaint.Name = this.aboutForm.controls["firstname"].value;
    this.Complaint.Surname = this.aboutForm.controls["surname"].value;
    this.Complaint.Address = this.aboutForm.controls["address"].value;
    this.Complaint.PostalCode = this.aboutForm.controls["postcode"].value;
    this.Complaint.ContactNumber = this.aboutForm.controls["contactnumber"].value;
    this.Complaint.DateOfBirth = this.Complaint.convertUTCDateToLocalDate(new Date(this.aboutForm.controls["dateofbirth"].value));
    this.Complaint.EmailAddress = this.aboutForm.controls["email"].value;
    this.Complaint.ContactType = this.aboutForm.controls["contactType"].value;

    if (this.ThirdPartyShow) {
      this.Complaint.ThirdPartyDetails.RelationshipType = this.aboutForm.controls["ThirdPartyRelationship"].value;
      this.Complaint.ThirdPartyDetails.Name = this.aboutForm.controls["ThirdPartyfirstname"].value;
      this.Complaint.ThirdPartyDetails.Surname = this.aboutForm.controls["ThirdPartysurname"].value;
      this.Complaint.ThirdPartyDetails.ContactNumber = this.aboutForm.controls["ThirdPartycontactnumber"].value;
      this.Complaint.ThirdPartyDetails.EmailAddress = this.aboutForm.controls["ThirdPartyemail"].value;
      this.Complaint.ThirdPartyDetails.Address = this.aboutForm.controls["ThirdPartyaddress"].value;
      this.Complaint.ThirdPartyDetails.PostalCode = this.aboutForm.controls["ThirdPartypostcode"].value;
      this.Complaint.ThirdPartyDetails.DateOfBirth = this.aboutForm.controls["ThirdPartydateofbirth"].value;
    }

    var isValid = true;
    if (this.Complaint.PreferedContactTimes.length > 0) {
      this.showPreferedTimeError = false;
    }
    else {
      this.showPreferedTimeError = true;
      isValid = false;
    }

    if (isValid) {
      this.Step2AnimState = false;
      this.Step3AnimState = true;      
      this.submitted = false;

      if (!this.Step2MILogged) {
        this.storage.saveComplaintMI(this.Complaint.ComplaintReference, this.Complaint, "step 2").subscribe(data => { });
        this.Step2MILogged = true;
      }
    }


  }

  validateComplaintReasonPage() {
    //this.Complaint.OtherComplaintReasons = [];
    console.log(this.ReasonForm.valid);
    this.submitted = true;
    if (this.showMainReasonError == false) {
      if (this.ReasonForm.valid) {
        if (this.mainOtherReasonChecked) {
          this.Complaint.ComplaintMainReason.Reason = this.ReasonForm.controls['mainOtherReasonInput'].value;
        }

        if (this.otherOtherReasonChecked) {
          // Check if there is a value in the input already then dont push again -- repeating issue on summary screen
        
            let otherReasonData = {} as ComplaintReasonInterface;
            otherReasonData.Reason = this.ReasonForm.controls['otherOtherReasonInput'].value;
            this.Complaint.OtherComplaintReasons.push(otherReasonData);
          
        }

        if (!this.Step3MILogged) {
          this.storage.saveComplaintMI(this.Complaint.ComplaintReference, this.Complaint, "step 3").subscribe(data => { });
          this.Step3MILogged = true;
        }
        
        this.Step3AnimState = false;
        this.Step4AnimState = true;
        window.scrollTo(0, 0)
        this.submitted = false;
      }
    }
  }



  validateComplaintDescriptionPage() {
    if (this.descriptionForm.invalid) {
      return;
    }

    if (Date.parse(this.descriptionForm.controls["datefrom"].value) > Date.parse(this.descriptionForm.controls["dateto"].value)) {
      console.log("to date cannot be before from date");
      this.showDateValidationError = true;
      return;
    }
    else {
      this.showDateValidationError = false;
    }

    this.Complaint.ComplaintFromDate = this.Complaint.convertUTCDateToLocalDate(new Date(this.descriptionForm.controls["datefrom"].value));
    this.Complaint.ComplaintToDate = this.Complaint.convertUTCDateToLocalDate(new Date(this.descriptionForm.controls["dateto"].value));
    this.Complaint.IsCurrentlyOccuring = this.IsCurrentlyOccuring;
    this.Complaint.ComplaintDescription = this.descriptionForm.controls["aboutclaim"].value;
    this.Complaint.ComplaintAffectedDescription = this.descriptionForm.controls["howeffectclaim"].value;

    this.Step4AnimState = false;
    this.Step5AnimState = true;    
    this.submitted = false;

    if (!this.Step4MILogged) {
      this.storage.saveComplaintMI(this.Complaint.ComplaintReference, this.Complaint, "step 4").subscribe(data => { });
      this.Step4MILogged = true;
    }
  }

  OnPreferedTimeSelected(preferedTime) {
    var isValidAdd = true;
    this.Complaint.PreferedContactTimes.forEach((item, index) => {
      if (item.timeOfDay == preferedTime) {
        this.Complaint.PreferedContactTimes.splice(index, 1);
        isValidAdd = false;
      }
    });

    this.preferedContactTimeArray.forEach(element => {
      if (element.name == preferedTime) {
        element.checked = isValidAdd;
      }
    });

    if (isValidAdd) {
      let preferedTimeObj = {} as PreferedContactTimeInterface;
      preferedTimeObj.timeOfDay = preferedTime;
      this.Complaint.PreferedContactTimes.push(preferedTimeObj);

      this.preferedContactTimeArray.forEach(element => {
        if (element.name == preferedTime) {
          element.checked = true;
        }
      });
    }
    this.showPreferedTimeError = false;
  }

  getToday(): string {
    var today = new Date();
    var validDate = new Date(today.setDate(today.getDate()));
    return validDate.toISOString().split('T')[0];
  }

  getYesterday(): string {
    var today = new Date();
    var validDate = new Date(today.setDate(today.getDate() - 1));
    return validDate.toISOString().split('T')[0];
  }

  getMinDate() {
    //debugger;
    var today = new Date();
    var validDate = today.getFullYear() - 18;
    var newDate = new Date();
    newDate.setFullYear(validDate);
    return newDate.toISOString().split('T')[0];
  }

  handleFileInput(event: any) {
    this.file = event.files.item(0);
    console.log("file ", this.file);
    if (this.file != null) {
      this.uploadFile();
      event.value = '';
    }

  }

  checkIfBlackListed(fileName: string) {
    let fileType = fileName.search(/(\.|\/)(bat|exe|cmd|sh|php([0-9])?|pl|cgi|386|dll|com|torrent|js|app|jar|pif|vb|vbscript|wsf|asp|cer|csr|jsp|drv|sys|ade|adp|bas|chm|cpl|crt|csh|fxp|hlp|hta|inf|ins|isp|jse|htaccess|htpasswd|ksh|lnk|mdb|mde|mdt|mdw|msc|msi|msp|mst|ops|pcd|prg|reg|scr|sct|shb|shs|url|vbe|vbs|wsc|wsf|wsh)$/i);
    if (fileType > 0) {
      const slicedFileType = fileName.slice(fileType, fileName.length);
      console.log('FILE TYPE REJECTED');
      return false;
    } else {
      console.log('FILE TYPE ACCEPTED');
      return true;
    }
  }


  uploadFile() {
    const filesizelimit = 10485760;
    let whitelisted = this.checkIfBlackListed(this.file.name);
    if (this.file.size < filesizelimit && whitelisted) {
      this.loadingspinner.show();
      this.documentUploadError = false;
      console.log('uploadFile');
      this.storage.uploadDocument(this.Complaint.ComplaintReference, this.file).subscribe(data => {
        console.log("uploadDocument response " + JSON.stringify(data));
        if (data.isSuccess) {
          this.documentUpload = null;
          this.documentUploaded = true;
          const document = {} as DocumentInterface;
          document.name = data.originalFileName;
          document.type = data.fileType;
          document.documentUrl = data.documentUrl;
          document.documentNameInDataStore = data.documentName;
          console.log("documentNameInDataStore ", data.documentName);
          this.Complaint.UploadedDocuments.push(document);
          console.log("documents ", this.Complaint.UploadedDocuments);
          this.loadingspinner.hide();
        } 
        else {
          this.documentUpload = 'Upload failed. Please try again';
          this.documentUploaded = false;
          this.loadingspinner.hide();
        }
      });
    } 
    else if (this.file.size > filesizelimit && whitelisted) {
      this.documentUpload = 'File must be smaller than 10MB';
      this.documentUploaded = false;
    } 
    else if (this.file.size < filesizelimit && !whitelisted) {
      this.documentUpload = 'This file type is not supported';
      this.documentUploaded = false;
    }
    return false;
  }

  onDeleteDocument(documentName) {
    this.loadingspinner.show();
    this.Complaint.UploadedDocuments.forEach((item, index) => {
      if (item.name == documentName) {
        this.storage.deleteDocument(item.documentNameInDataStore).subscribe(data => {
          if (data.isSuccess) {
            this.Complaint.UploadedDocuments.splice(index, 1);
            this.loadingspinner.hide();
            console.log("documents ", this.Complaint.UploadedDocuments);
          }
          else {
            this.loadingspinner.hide();
          }
        });
      }
    });
  }

  finishClick() {
    window.location.href = "https://www.tsb.co.uk/personal/";
  }

  onAddressChange(){
    console.log("address changed ");
  }
}
