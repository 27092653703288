import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material';
import { PortallogoutComponent } from '../../shared/dialogs/portallogout/portallogout.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  constructor(public dialog: MatDialog, public router: Router) { }

  ngOnInit() {
  }
  Logout(): void {
    const dialogRef = this.dialog.open(PortallogoutComponent, {
      width: '90%'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.router.navigate(["adminportal"]);
    });
  }

}
