import { ComplaintReasonInterface } from './../interfaces/complainReason.model.interface';
import { ComplaintInterface } from './../interfaces/complaint.model.interface';
import { ComplaintReason } from './complaintReason.model';
import { PreferedContactTimeInterface } from './../interfaces/PreferedContactTime.model.interface';
import { FinancialLossesInterface } from './../interfaces/financialLosses.model.interface';
import { DocumentInterface } from "../interfaces/document.model.interface";
import { ThirdPartyInterface } from '../interfaces/thirdParty.model.interface';
import { ThirdParty } from './thirdParty.model';

export class Complaint implements ComplaintInterface{    
    ComplaintReference: string;
    Title:string;
    Name: string;
    Surname: string;
    ContactNumber:string;
    ContactType: string;
    DateOfBirth: Date = new Date();
    EmailAddress: string;
    PreferedContactTimes: Array<PreferedContactTimeInterface> = new Array<PreferedContactTimeInterface>();
    Address: string;
    PostalCode: string;
    ThirdPartyDetails: ThirdPartyInterface = new ThirdParty();
    ComplaintMainReason: ComplaintReasonInterface = new ComplaintReason();
    OtherComplaintReasons : Array<ComplaintReasonInterface> = new Array<ComplaintReasonInterface>();
    ComplaintFromDate: Date = new Date();
    ComplaintToDate: Date = new Date();
    IsCurrentlyOccuring: boolean;
    ComplaintDescription: string;
    ComplaintAffectedDescription: string;
    ComplaintTrackingId: string;
    FinancialLosses : Array<FinancialLossesInterface> = new Array<FinancialLossesInterface>();
    UploadedDocuments: Array<DocumentInterface> = new Array<DocumentInterface>();
    isComplaintSubmitted: boolean;
    isComplaintRecorded: boolean;
    isComplaintUnderReview: boolean;
    isComplaintResloved: boolean;
    disableComplaintSubmitted: boolean;
    disableComplaintRecorded: boolean;
    disableComplaintUnderReview: boolean;
    disableComplaintResolved: boolean;
    status: string;

    public convertUTCDateToLocalDate(date: Date): Date {
        const offset = date.getTimezoneOffset() / 60;
        const hours = date.getHours();
        date.setHours(hours - offset);
        return date;
    }

    public prepareDateForDateControl(date: Date): Date {
        if (!date) {
            return null;
        }
        const dateString = new Date(date).toDateString();
        return new Date(dateString);
    }
}