import { Directive, ElementRef, HostListener  } from '@angular/core';

@Directive({
  selector: '[appDatemask]'
})
export class DatemaskDirective {

  constructor(private el: ElementRef) { }

  @HostListener('keyup',['$event']) onEvent($event){
    var v = this.el.nativeElement.value;
    if (v.match(/^\d{2}$/) !== null) {
      this.el.nativeElement.value = v + '/';
      
    } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
      this.el.nativeElement.value = v + '/';
    }
  }

}
