import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DatemaskService {

  dayInputCheckInCorrect: boolean;
  monthInputCheckInCorrect: boolean;
  yearInputCheckInCorrect: boolean;
  incorrectlyFormatted: boolean;

  dayInputCheckInCorrect2: boolean;
  monthInputCheckInCorrect2: boolean;
  yearInputCheckInCorrect2: boolean;
  incorrectlyFormatted2: boolean;

  constructor() { }

  CheckDateKeyupInput(event, element, futureAllow: boolean) {
    console.log(event.key)
    if (event.key !== "Backspace" ) {
          this.dayInputCheckInCorrect = false;
          this.monthInputCheckInCorrect = false;
          this.yearInputCheckInCorrect = false;
          this.incorrectlyFormatted = false;
          const v = element.value;

          if (v.match(/^\d{2}$/) !== null) {
            element.value = v + '/';

          } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
            element.value = v + '/';
          }
          const re = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;

          if (v.length >= 2) {
            if (v.substring(0, 2) < 1 || v.substring(0, 2)  > 31) {
              this.dayInputCheckInCorrect = true;

            // element.value = '';

            }
          }
            // month value between 1 and 12
            if (v.length >= 5) {
              const newvar = v.substring(3, 5);
              if (newvar < 1 || newvar > 12) {
                this.monthInputCheckInCorrect = true;
                const newvar2 = v.substring(0, 3);

              }
            }
            if (v.length === 10) {
              const newvar = element.value.match(re);
              if (newvar == null) {
                this.incorrectlyFormatted = true;
                return;
              }
              if (futureAllow) {
                return;
              } else {

                // change to useable format for javascript date


                const dateString = newvar[0];
                const splitDate = dateString.split('/');
                const month = splitDate[1] - 1; // Javascript months are 0-11

                const date = new Date(splitDate[2], month, splitDate[0]);
                const currentDate = new Date();
                if (date > currentDate) {
                  this.yearInputCheckInCorrect = true;
                  console.log(date + " --- " + currentDate);
                }
              }
            }
        }
    }

    CheckDateKeyupInput2(event, element, futureAllow: boolean) {
      console.log(event.key)
      if (event.key !== "Backspace" ) {
          this.dayInputCheckInCorrect2 = false;
          this.monthInputCheckInCorrect2 = false;
          this.yearInputCheckInCorrect2 = false;
          this.incorrectlyFormatted2 = false;
          const v = element.value;
          if (v.match(/^\d{2}$/) !== null) {
            element.value = v + '/';
          } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
            element.value = v + '/';
          }
          const re = /^(\d{1,2})\/(\d{1,2})\/(\d{4})$/;
          if (v.length >= 2) {
            console.log(v);
            if (v.substring(0, 2) < 1 || v.substring(0, 2)  > 31) {
              this.dayInputCheckInCorrect2 = true;
            // element.value = '';
            }
          }
            // month value between 1 and 12
            if (v.length >= 5) {
              const newvar = v.substring(3, 5);
              console.log(newvar);
              if (newvar < 1 || newvar > 12) {
                this.monthInputCheckInCorrect2 = true;
                const newvar2 = v.substring(0, 3);
              }
            }
            if (v.length === 10) {
              const newvar = element.value.match(re);
              console.log(newvar);
              if (newvar[0] == null) {
                this.incorrectlyFormatted2 = true;
                return;
              }
              if (futureAllow) {
                return;
              } else {
                console.log(newvar[0]);
                // change to useable format for javascript date
                const dateString = newvar[0];
                const splitDate = dateString.split('/');
                const month = splitDate[1] - 1; // Javascript months are 0-11
                const date = new Date(splitDate[2], month, splitDate[0]);
                const currentDate = new Date();
                if (date > currentDate) {
                  this.yearInputCheckInCorrect2 = true;
                  console.log(date + " --- " + currentDate);
                }
              }
            }
          }
    }
  }


