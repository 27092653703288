import { Csv } from './../../shared/models/csv.model';
import { CsvInterface } from './../../shared/interfaces/csv.model.interface';
import { Component, OnInit } from '@angular/core';
import { LoaderspinnerService } from '../../services/loaderspinner.service';
import { trigger, style, query, stagger, transition, animate } from '@angular/animations';
import { StorageService } from '../../services/storage.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
  animations: [
    trigger('ngIfAnimation', [
      transition('void => *', [
        query(':self', style({ opacity: 0 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('1s ease-out', style({ opacity: 1, }),
          )]), { optional: false }),
      ]),
      transition('* => void', [
        query(':self', style({ opacity: 1 }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0s ease-out', style({ opacity: 0 }),
          )]), { optional: false }),
      ])
    ]),
    trigger('MoveUpShortAnimation', [
      transition('void => *', [
        query(':self', style({ opacity: 0, transform: 'translateY(50%)' }), { optional: false }),
        query(':self', stagger('0ms', [
          animate('0.5s ease-out', style({ opacity: 1, transform: 'translateY(0)' }),
          )]), { optional: false }),
      ]),
      transition('* => void', [
        query(':self', stagger('0ms', [
          animate('0.2s ease-out', style({ opacity: 0, transform: 'translateY(50%)' }),
          )]), { optional: false }),
      ])

    ])]
})
export class FilesComponent implements OnInit {

  panelOpenState = false;

  csvDataList: Array<CsvInterface>;
  query:string;

  constructor(private storage: StorageService, private loadingspinner: LoaderspinnerService, ) { }

  ngOnInit() {
   // this.loadingspinner.show();    
    this.getAllCSVFiles();
  }

  downloadDailyCsv(fileName, mediaLink) {
    const lastCharacters = fileName.slice(fileName.length - 4);    
    // if (lastCharacters !== '.csv') {
    //   fileName = fileName + '.csv';
    // }
    this.loadingspinner.show();
    this.storage.donwloadCSV(fileName).subscribe(data => {     
      const buff = require('buffer/').Buffer.from(data[0]);
      const fileBlob = new Blob([buff], { type: 'csv' });
      saveAs(new Blob([fileBlob]), fileName);

      this.csvDataList.forEach((csv, index) => {
        if ((((csv.fileName === fileName) || (csv.fileName === (fileName.substring(0, fileName.length - 4) ))) && csv.mediaLink === mediaLink)) {
          csv.status = 'downloaded';          
          this.storage.updateCSVDownloadStatus(fileName).subscribe(res => {
            // console.log(res);
          });
        }
      });
      this.loadingspinner.hide();     
    });
  }

  getAllCSVFiles(){
    this.csvDataList = new Array<CsvInterface>();
    this.loadingspinner.show();
    this.storage.getAllCSVFiles().subscribe(data => {     
      for (let x = 0; x < data.length; x++) {
        const csvData = {} as Csv;
        csvData.fileName = data[x].fileName;
        csvData.mediaLink = data[x].mediaLink;
        csvData.status = data[x].status;
        
        if(data[x].report_type == "Data Report"){
          csvData.reportType = "Data Report";
          csvData.folderName = data[x].fileName.substring(9);
        }
        else if(data[x].report_type == "MI Report"){
          csvData.reportType = "MI Report";
          csvData.folderName = data[x].fileName.substring(11);
        }
        else if(data[x].report_type == "XML Report"){
          csvData.reportType = "XML Report";
          csvData.folderName = data[x].fileName.substring(9);
        }
        
        
        this.csvDataList.push(csvData);
      }
      this.loadingspinner.hide(); 
    });
  }

  generateCSV(){
    this.loadingspinner.show();
    this.storage.generateCsv().subscribe(data => {
      if(data.isSuccess){
        this.getAllCSVFiles();
      }
    });
  }
}
