import { Component, OnInit } from '@angular/core';
import {MatDialogRef} from '@angular/material';

@Component({
  selector: 'app-portallogout',
  templateUrl: './portallogout.component.html',
  styleUrls: ['./portallogout.component.scss']
})
export class PortallogoutComponent implements OnInit {

  enableLogoutButton:boolean = false;

  constructor( public dialogRef: MatDialogRef<PortallogoutComponent>) { }

  ngOnInit() {
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

  ConfirmToggle(ischecked){
   console.log(ischecked);
    if (ischecked) {
      this.enableLogoutButton = true;
    } else {
      this.enableLogoutButton = false;
    }
  }
}
